import React, { useState, useEffect } from "react";
import { db, auth } from "./firebase.js";
import { doc, setDoc, collection, onSnapshot, serverTimestamp, query, where, orderBy } from "firebase/firestore"
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import setCss from "./setCss.js"
import Nav from "./live-nav.js";
import Loading from "./live-loading.js";
import Title from "./live-title.js";
import NewMessage from "./live-newmessage.js"
import Pics from "./live-pics.js";
import MessagesComp from "./live-messages.js";

function Live() {
  const [ReceivedData, setReceivedData] = useState(false);
  const [LiveFlag, setLiveFlag] = useState(false);
  const [CastFlag, setCastFlag] = useState(false);
  const [AdminFlag, setAdminFlag] = useState(false);
  const [LoggedUser, setLoggedUser] = useState("");
  const [Messages, setMessages] = useState([]);
  const [Transmition, setTransmition] = useState({});
  const [Vista, setVista] = useState("videoFrame");
  const [Items, setItems] = useState([]);
  const [ItemsSorted, setItemsSorted] = useState([]);
  const [Estate, setEstate] = useState("");
  const [ActiveItem, setActiveItem] = useState("");
  const [Loaded, setLoaded] = useState(false);

  useEffect(() => {
    setCss('/assets/live.css');
    setLoaded(true)
    if (Loaded) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          onSnapshot(doc(db, "Bollo/JCarrizo/Usuarios", user.uid), (userDoc) => {
            if (userDoc.exists()) {
              setAdminFlag(userDoc.data().admin);
              setLoggedUser({ uid: user.uid, ...userDoc.data() });
              document.body.classList.toggle('menu', !userDoc.data().nombre)
              setDoc(doc(db, "Bollo/vivo/Usuarios", user.uid), {
                ts: serverTimestamp(),
                nombre: userDoc.data().nombre,
                email: userDoc.data().email,
                telefono: userDoc.data().telefono,
                foto: userDoc.data().foto
              }, { merge: true });
              document.body.classList.toggle('anonimo', (!userDoc.data().nombre || !userDoc.data().telefono || !userDoc.data().email));
            } else {
              let userData = {
                nombre: user.displayName || '',
                email: user.email || '',
                telefono: user.phoneNumber || '',
                foto: user.photoURL || '',
                admin: false
              }
              setDoc(doc(db, "Bollo/JCarrizo/Usuarios", user.uid), userData);
            }
          })
        } else {
          signInAnonymously(auth);
        }
      });
      onSnapshot(
        query(
          collection(db, "Bollo/JCarrizo/Mensajes"),
          where("date", ">=", new Date().valueOf() - (12 * 60 * 60 * 1000)), //Last 12 hours
          orderBy("date", "asc")
        ),
        (querySnapshot) => {
          let items = [];
          querySnapshot.docs.forEach(doc => {
            items.push({
              ...doc.data(),
              id: doc.id,
            })
          })
          setMessages(items.reverse());
        })
      onSnapshot(
        query(
          collection(db, "Bollo/JCarrizo/Lotes"),
          where("publicado", "==", true),
        ),
        (querySnapshot) => {
          let items = [];
          querySnapshot.docs.forEach(doc => {
            items.push({
              ...doc.data(),
              id: doc.id,
            })
          })
          setItems(items);
        })
      onSnapshot(
        query(
          collection(db, "Bollo/JCarrizo/Inmuebles"),
          where("numero", "!=", 0),
        ),
        (querySnapshot) => {
          setEstate(
            querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
              select: 1000,
            }))
          )
        })
      onSnapshot(collection(db, "Bollo"), (snapshot) => {
        for (let doc of snapshot.docs) {
          if (doc.id === 'JCarrizo')
            setTransmition(doc.data());
          if (doc.id === 'vivo')
            setLiveFlag(doc.data().vivo)
          if (doc.id === 'cast')
            setCastFlag(doc.data().transmitting)
          setReceivedData(true);
        }
      })
    }
  }, [Loaded]);

  useEffect(() => {
    let snap = [], sorted = []
    for (let item of Items)
      if (!Transmition.fechasubasta || item.fechasubasta === Transmition.fechasubasta)
        snap.push([(Number(item.numero) + 10000), item])
    snap.sort()
    for (let i of snap)
      sorted.push(i[1]);
    setItemsSorted(sorted);
  }, [Transmition, Items]);

  useEffect(() => {
    for (let item of Items)
      if (item.id === Transmition.loteActivo)
        setActiveItem({ ...item, property: false });
    for (let item of Estate)
      if (item.id === Transmition.loteActivo)
        setActiveItem({ ...item, property: true });
  }, [Transmition, Items, Estate]);

  return (
    <div className="App">
      <Nav
        admin={AdminFlag}
        user={LoggedUser}
        setLoggedUser={setLoggedUser}
        autoSignIn={true}
      />
      {
        !ReceivedData ? (<Loading />) : ''
      }

      {((LiveFlag && CastFlag && !!LoggedUser.uid) || AdminFlag) ? (
        < section
          id="vivo"
        >
          <main
            vista={Vista}
          >
            <div
              id="video"
            >
              <iframe
                src={(CastFlag || AdminFlag) ? 'https://cast.josecarrizo.com.ar' : ''}
                frameBorder="0"
                id="videoFrame"
                onLoad={e =>
                  document.body.classList.toggle('transmitiendo', e.target.src.includes('cast.josecarrizo.com.ar'))
                }
                allow="fullscreen"
                allowFullScreen={true}
                title='Video Frame'
              >
              </iframe>
              <div
                className="over"
                onClick={() => setVista('videoFrame')}
              ></div>
            </div>
            <MessagesComp
                Messages={Messages}
                ActiveUser={LoggedUser}
            />
            <Pics
              ActiveItem={ActiveItem}
              Transmition={Transmition}
            />
            <NewMessage
              ActiveItem={ActiveItem}
              Items={ItemsSorted}
              ActiveUser={LoggedUser}
              Transmition={Transmition}
            />
            {!!LoggedUser.uid ? '' : (
              <div
                id="anonimo"
              >
                <div>
                  Debe completar el registro para participar en la transmisión
                </div>
              </div>
            )}
          </main>
        </section >

      ) : (<Title />)}


    </div>
  );
}

export default Live;
