import React from 'react'

const EstateList = (props) => {
    return (
        <section
            id="listado-Inmuebles"
        >
            <h1>
                Inmuebles
            </h1>
            {props.Estate.map(item => {
                return (
                    <div
                        id={item.id || ''}
                        key={item.id}
                        className={(item.id === props.Transmition.loteActivo ? 'activo ' : '') + (props.showSearchItem(item, props.SearchItem) ? '' : 'searchHidden')}
                        onClick={e => props.saveItemProperty('loteActivo', item.id)}
                    >
                        <strong>({item.numero})</strong> {props.capitalize(item.titulo)}
                    </div>
                )
            })}
        </section>
    )
}
export default EstateList
