import React from 'react'

const ItemsList = (props) => {
    return (
        <section
            className='List'
            style={{
                gridArea: 'list-' + props.Title
            }}
        >
            <h1>
                {props.Title}
                <a
                    href={'#' + props.newItem}
                >
                    Nuevo
                </a>
            </h1>

            {Object.keys(props.Items).map((id, i) => {
                let item = props.Items[id],
                    order = item.numero || 0;
                if (order === 0)
                    order += 1000;
                if (!item.publicado)
                    order += 10000;
                return (
                    <div
                        key={id}
                        className={item.adjudicado ? 'adjudicado' : ''}
                        style={{
                            order: order,
                            fontWeight: (props.ActiveItem.id === id) ? 'bold' : 'unset',
                            border: (props.ActiveItem.id === id) ? 'solid 1px #aaa' : 'unset'
                        }}
                    >
                        <input
                            type="checkbox"
                            className="publicado"
                            title="Publicado"
                            defaultChecked={item.publicado || false}
                            onChange={e => props.saveItemProperty(id, 'publicado', e.target.checked)}
                        />
                        <input
                            type="number"
                            min="0"
                            className="reordena"
                            defaultValue={item.numero || 0}
                            onChange={e => props.saveItemProperty(id, 'numero', e.target.value)}
                        />
                        <input
                            type="checkbox"
                            className="ventaDirecta"
                            title="Venta Directa"
                            defaultChecked={item.vdirecta || false}
                            onChange={e => props.saveItemProperty(id, 'vdirecta', e.target.checked)}
                        />
                        <a
                            href={'#' + id}
                        >
                            {item.titulo || '----------'}
                        </a>
                        <a
                            href={'#' + id}
                        >
                            {item.oferta || ''}
                        </a>
                        <input
                            type="checkbox"
                            className="cargarFotos"
                            title="Cargar Fotos en Presentación"
                            defaultChecked={item.cargarFotos || false}
                            onChange={e => props.saveItemProperty(id, 'cargarFotos', e.target.checked)}
                        />
                    </div>
                )
            })}
        </section>
    )
}

export default ItemsList;