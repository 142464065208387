import React from "react";

const Categories = (props) => {
    return (
        <nav
            className={props.Categories.length ? 'categorias' : ''}
        >
            <div>
                <h1>
                    Categorías
                </h1>
                <ul>
                    {props.Categories.sort().map(cat => {
                        return (
                            <li
                                key={'EstateCat,' + cat}
                            >
                                <label>
                                    <input
                                        type='checkbox'
                                        style={{ display: 'none' }}
                                        onChange={e => {
                                            let pre = { ...props.CatSearch };
                                            pre[cat] = e.target.checked;
                                            props.setCatSearch(pre)
                                        }}
                                        checked={props.CatSearch[cat] || false}
                                    />
                                    <span>
                                        {cat}
                                    </span>
                                </label>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </nav>
    );
}

const Section = (props) => {
    const Grid = (sale = 2, rent = 2) => {
        let r = '' + sale + '-' + rent + ',',
            n = 0,
            EstateList = [];
        for (let id in props.Estate)
            if ((sale === 2 || Number(props.Estate[id].vdirecta) === sale))
                if ((rent === 2 || Number(props.Estate[id].alquiler) === rent || (rent === 0 && !props.Estate[id].alquiler))) {
                    let pic = '';
                    for (let p of props.Estate[id].fotos)
                        if (!pic && p.toLowerCase().includes('.jpg'))
                            pic = p;
                    EstateList.push([props.Estate[id].titulo.toUpperCase(), id, pic, !Array.isArray(props.EstateList) || props.EstateList.includes(id)]);
                    if (!Array.isArray(props.EstateList) || props.EstateList.includes(id)) n++;
                }
        EstateList.sort();
        return (
            <div
                key={r}
                className={'category' + (Array.isArray(props.EstateList) ? ' search' : '') + (n === 0 ? ' searchHidden' : '')}
            >
                <h1>
                    {(rent === 2 && sale === 2) ? 'Todos los Inmuebles' : ''}
                    {(rent === 2 && sale === 1) ? 'Inmuebles en Venta' : ''}
                    {(rent === 2 && sale === 0) ? 'Inmuebles en Subasta' : ''}
                    {(rent === 1 && sale === 2) ? 'Inmuebles en Alquiler' : ''}
                    {(rent === 1 && sale === 1) ? 'Inmuebles en Alquiler y Venta' : ''}
                    {(rent === 1 && sale === 0) ? 'Inmuebles en Alquiler y Subasta' : ''}
                    {(rent === 0 && sale === 2) ? 'Inmuebles en Venta o Subasta' : ''}
                    {(rent === 0 && sale === 1) ? 'Inmuebles en Venta' : ''}
                    {(rent === 0 && sale === 0) ? 'Inmuebles en Subasta' : ''}
                </h1>
                {EstateList.map(item => {
                    let key = 'item,' + item[1] + ',' + r;
                    return (
                        <a
                            href={'#' + item[1]}
                            key={key}
                            className={!item[3] ? 'searchHidden' : ''}
                        >
                            <img
                                className="loading"
                                src={item[2]}
                                onError={e => e.target.parentNode.remove()}
                                onLoad={e => e.target.classList.remove('loading')}
                                alt=''
                            />
                            <h3>
                                {item[0]}
                            </h3>
                        </a>
                    )
                })}
            </div>
        )
    }

    return (
        <div id="serviciosinmoviliarios">
            <section
                id="tasaciones"
            >
                <h1>
                    Tasaciones
                </h1>
                <p>
                    Tasamos su inmueble, intentando llegar al valor real del mismo.-
                </p>
                <p>
                    Es decir, que la estimación del valor de su inmueble no genere un precio deformado, ya sea un valor excesivo o muy inferior al que realmente le corresponde.-
                </p>
            </section>
            <section>
                <h1>
                    Servicios Inmobiliarios
                </h1>
                <p>
                    Asesoramiento profesional para la compra o venta de su propiedad, respaldando nuestro trabajo en la experiencia adquirida ejerciendo el corretaje inmobiliario.-
                </p>
            </section>
            <section
                id="grillaInmuebles"
            >
                <div
                    className="grilla"
                >
                    {Grid(0, 0)}
                    {Grid(2, 1)}
                    {Grid(1, 0)}
                </div>
            </section>
        </div>
    );
}
export const
    EstateComp = Section,
    EstateCategoriesComp = Categories
