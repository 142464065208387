import React from 'react'

const ItemsList = (props) => {
    return (
        <section
            className='doble'
            id="listado-Lotes">
            <h1>
                Lotes
            </h1>
            {props.Items.map(item => {
                return (
                    <div
                        id={item.id || ''}
                        key={item.id}
                        className={(item.adjudicado ? 'adjudicado ' : '') + (item.id === props.Transmition.loteActivo ? 'activo ' : '') + (props.showSearchItem(item, props.SearchItem) ? '' : 'searchHidden')}
                        onClick={e => props.saveItemProperty('loteActivo', item.id)}
                    >
                        {item.categoria.join(' / ')} - <strong>({item.numero})</strong> {props.capitalize(item.titulo)}
                    </div>
                )
            })}
        </section>
    )
}
export default ItemsList