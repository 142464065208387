import React from 'react'
const Buttons = (props) => {
    return (
        <div id="conectados">
            <span>{props.Users.length}</span>
            <div>
                {props.Users.map(usr => {
                    return (
                        <div
                            key={'user' + usr.id}
                            title={usr.telefono + ' - ' + usr.email}
                        >
                            <span>
                                {usr.nombre}
                            </span>
                            <img
                                src={usr.foto}
                                alt='User Pic'
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default Buttons