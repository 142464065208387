import React, { useState, useEffect } from "react";
import { db } from "./firebase.js";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore"
import setCss from "./setCss.js"
import Nav from "./root-nav.js";
import Footer from "./root-footer.js";
import Loader from "./root-loader.js";
import { Auctions, AuctionCategories } from "./root-auctions.js";
import { EstateComp, EstateCategoriesComp } from "./root-estate.js";
import ItemComp from "./root-item.js";

function Root() {
	const [Hash, setHash] = useState('');
	const [LiveFlag, setLiveFlag] = useState(false);
	const [ActiveItem, setActiveItem] = useState({});
	const [Items, setItems] = useState({});
	const [ItemsList, setItemsList] = useState([]);
	const [ItemCategories, setItemCategories] = useState({ auction: [], sale: [], render: false });
	const [Estate, setEstate] = useState({});
	const [EstateList, setEstateList] = useState([]);
	const [EstateCategories, setEstateCategories] = useState([]);
	const [NextAuctionDate, setNextAuctionDate] = useState('');
	const [Search, setSearch] = useState('');
	const [CatSearch, setCatSearch] = useState({});
	const [EscKey, setEscKey] = useState(false);
	const [Loaded, setLoaded] = useState(false);

	useEffect(() => {
		setCss('/assets/store.css');
		setLoaded(true)
		if (Loaded) {
			setHash(window.location.hash.slice(1))
			window.addEventListener('hashchange', () => {
				setHash(window.location.hash.slice(1))
			});
			document.addEventListener("keyup", evt => {
				if (evt.keyCode === 27)
					setEscKey(true)
			});
			onSnapshot(
				doc(db, "Bollo", 'vivo'),
				(liveDoc) => {
					setLiveFlag(!!liveDoc.data().vivo)
					setNextAuctionDate(liveDoc.data().proxSubasta)
				})
			onSnapshot(
				query(
					collection(db, "Bollo/JCarrizo/Lotes"),
					where("publicado", "==", true)
				),
				(querySnapshot) => {
					let items = {};
					for (let item of querySnapshot.docs)
						if (!!item.data().numero && item.data().numero > 0)
							items[item.id] = {
								id: item.id,
								...item.data()
							}
					setItems(items)
				})
			onSnapshot(
				query(
					collection(db, "Bollo/JCarrizo/Inmuebles"),
					where("publicado", "==", true)
				),
				(querySnapshot) => {
					let estate = {};
					for (let item of querySnapshot.docs)
						if (!!item.data().numero && item.data().numero > 0)
							estate[item.id] = {
								id: item.id,
								...item.data()
							}
					setEstate(estate)
				})
		}
	}, [Loaded]);

	useEffect(() => {
		if (EscKey) {
			if (!!ActiveItem.id)
				window.location = '#' + ActiveItem.section
			else
				window.location = '#'
			setEscKey(false)
		}
	}, [EscKey, ActiveItem]);

	useEffect(() => {
		if (ActiveItem.section) document.body.setAttribute('sec', ActiveItem.section);
		else document.body.removeAttribute('sec', ActiveItem.section);
	}, [ActiveItem]);

	useEffect(() => {
		const listClean = (list, raw) => {
			if (!raw)
				return 'all';
			let tx = normalizeStr(raw).split(' '),
				r = [];
			for (let l in list) {
				let str = normalizeStr(list[l].titulo + list[l].descripcion + list[l].categoria.join(' ')).replaceAll(' ', ''),
					keep = true;
				for (let t of tx)
					if (keep && !!t)
						keep = str.includes(t);
				if (keep) r.push(l)
			}
			return (r)
		}
		setItemsList(listClean(Items, Search));

		let estateSearch = [Search];
		for (let cat in CatSearch)
			if (CatSearch[cat])
				estateSearch.push(cat)
		setEstateList(listClean(Estate, estateSearch.join(' ')));
	}, [Items, Estate, Search, CatSearch]);

	useEffect(() => {
		if (!!Items[Hash])
			setActiveItem({ ...Items[Hash], section: 'subastas' });
		else if (!!Estate[Hash])
			setActiveItem({ ...Estate[Hash], section: 'serviciosinmoviliarios' });
		else if (Hash.toLowerCase() === 'subastas' || Hash.toLowerCase() === 'serviciosinmoviliarios')
			setActiveItem({ section: Hash.toLowerCase() });
		else if (!!Hash && Object.keys(Items).length && Object.keys(Estate).length)
			window.location = '#';
		else
			setActiveItem({});
		window.scrollTo(0, 0)
	}, [Items, Estate, Hash]);

	useEffect(() => {
		let auctionCategs = new Set(),
			saleCategs = new Set()
		for (let id in Items)
			if (Items[id].categoria && Items[id].categoria.length)
				for (let c of Items[id].categoria)
					if (Items[id].vdirecta)
						saleCategs.add(c);
					else
						auctionCategs.add(c);
		setItemCategories({
			auction: [...auctionCategs].sort(),
			sale: [...saleCategs].sort(),
			render: !!(auctionCategs.size + saleCategs.size)
		})
	}, [Items]);

	useEffect(() => {
		let categs = new Set()
		for (let id in Estate)
			if (Estate[id].categoria && Estate[id].categoria.length)
				for (let c of Estate[id].categoria)
					categs.add(c);
		setEstateCategories([...categs].sort())
	}, [Estate]);

	const normalizeStr = str => {
		return str
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.replace(/[^a-z0-9]/gmi, ' ')
			.replace(/\s+/g, ' ');
	}

	const toTitleCase = (phrase) => {
		return phrase
			.replaceAll('-', ' - ')
			.replaceAll('  ', ' ')
			.replaceAll('  ', ' ')
			.toLowerCase()
			.split(' ')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	return (
		<div
			className={'App' + (LiveFlag ? ' vivo' : '')}
		>
			{(ItemCategories.render || EstateCategories.length) ? (
				<Nav
					Hash={Hash}
					Search={Search}
					setSearch={setSearch}
					Categories={ActiveItem.section === 'subastas' ? ItemCategories : EstateCategories}
					normalizeStr={normalizeStr}
					toTitleCase={toTitleCase}
					ActiveItem={ActiveItem}
					CatSearch={CatSearch}
					setCatSearch={setCatSearch}
					renderItems={ItemCategories.render}
					renderEstate={!!EstateCategories.length}
				/>
			) : (
				<Loader />
			)}
			{(ActiveItem.section && (ItemCategories.render || EstateCategories.length)) ? (
				<main>
					{(ActiveItem.section === 'subastas' && ItemCategories.render) ? (
						<AuctionCategories
							Categories={ItemCategories}
							normalizeStr={normalizeStr}
							toTitleCase={toTitleCase}
							setSearch={setSearch}
						/>
					) : ''}
					{ActiveItem.section === 'serviciosinmoviliarios' ? (
						<EstateCategoriesComp
							Categories={EstateCategories}
							normalizeStr={normalizeStr}
							toTitleCase={toTitleCase}
							setSearch={setSearch}
							Search={Search}
							CatSearch={CatSearch}
							setCatSearch={setCatSearch}
						/>
					) : ''}
					{ActiveItem.id ? (
						< ItemComp
							ActiveItem={ActiveItem}
						/>
					) : ''}
					{(ActiveItem.section === 'subastas' && ItemCategories.render) ? (
						<Auctions
							NextAuctionDate={NextAuctionDate}
							Items={Items}
							ItemsList={ItemsList}
							Categories={ItemCategories}
							normalizeStr={normalizeStr}
						/>
					) : ''}
					{ActiveItem.section === 'serviciosinmoviliarios' ? (
						<EstateComp
							Estate={Estate}
							EstateList={EstateList}
							EstateCategories={EstateCategories}
							normalizeStr={normalizeStr}
						/>
					) : ''}

				</main>
			) : ''}
			<Footer />
		</div>
	);
}

export default Root;
