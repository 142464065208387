import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "./firebase";
import { doc, setDoc, collection, onSnapshot, query, where } from "firebase/firestore"
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import setCss from "./setCss"

import Buttons from "./remote-buttons.js";
import ItemsList from "./remote-itemslist.js";
import EstateList from "./remote-estatelist.js";
import MessagesComp from "./remote-messages.js";
import Connected from "./remote-connected.js";
import MConnected from "./messages-connected.js";

function Remote(props) {
  const [Items, setItems] = useState([]);
  const [SortedItems, setSortedItems] = useState([]);
  const [AuctionDates, setAuctionDates] = useState([]);
  const [Estate, setEstate] = useState([]);
  const [Messages, setMessages] = useState([]);
  const [SearchItem, setSearchItem] = useState('');
  const [Transmition, setTransmition] = useState({});
  const [Live, setLive] = useState(false);
  const [Casting, setCasting] = useState(false);
  const [SortByCategories, setSortByCategories] = useState(false);
  const [Loaded, setLoaded] = useState(false);
  const signingOut = useRef(false);
  const [Users, setUsers] = useState([]);


  useEffect(() => {
    setCss('/assets/remote.css');
    setLoaded(true);
    if (Loaded) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          onSnapshot(doc(db, "Bollo/JCarrizo/Usuarios", user.uid), (userDoc) => {
            if (userDoc.exists() && userDoc.data().admin) {
              onSnapshot(
                query(
                  collection(db, "Bollo/JCarrizo/Lotes"),
                  where("publicado", "==", true)
                ),
                (querySnapshot) => {
                  let items = [], auctionDates = new Set();
                  for (let doc of querySnapshot.docs) {
                    if (!!doc.data().fechasubasta)
                      auctionDates.add(doc.data().fechasubasta);
                    items.push({
                      ...doc.data(),
                      id: doc.id,
                    });
                  }
                  setItems(items);
                  setAuctionDates([...auctionDates].sort())
                })
              onSnapshot(
                query(
                  collection(db, "Bollo/JCarrizo/Inmuebles"),
                  where("publicado", "==", true)
                ),
                (querySnapshot) => {
                  let items = [], sort = [];
                  for (let doc of querySnapshot.docs)
                    sort.push([
                      (Number(doc.data().numero) + 1000000 || '') + (doc.data().titulo)
                      , {
                        ...doc.data(),
                        id: doc.id,
                      }]);
                  sort.sort();
                  for (let i of sort)
                    items.push(i[1]);
                  setEstate(items);
                })
              onSnapshot(
                query(
                  collection(db, "Bollo/JCarrizo/Mensajes"),
                  where("ts", ">=", new Date(new Date().setHours(new Date().getHours() - 24))),
                ),
                (querySnapshot) => {
                  let items = [];
                  for (let doc of querySnapshot.docs)
                    items.push({
                      ...doc.data(),
                      id: doc.id,
                    });
                  setMessages(items.reverse());
                })
              onSnapshot(
                doc(collection(db, "Bollo"), "vivo"),
                (querySnapshot) => {
                  setLive(querySnapshot.data().vivo || false)
                })
              onSnapshot(
                doc(collection(db, "Bollo"), "JCarrizo"),
                (querySnapshot) => {
                  setTransmition({ ...querySnapshot.data() })
                })
              onSnapshot(
                doc(collection(db, "Bollo"), "cast"),
                (querySnapshot) => {
                  setCasting(querySnapshot.data().transmitting || false)
                })
              onSnapshot(
                query(
                  collection(db, "Bollo/vivo/Usuarios"),
                  where("ts", ">=", new Date(new Date().setHours(new Date().getHours() - 2)))
                ),
                (snapshot) => {
                  setUsers(
                    snapshot.docs.map((doc) => ({
                      ...doc.data(),
                      id: doc.id,
                    }))
                  )
                })
            } else {
              signingOut.current = true;
              signOut(auth).then(() => {
                window.location = '/';
              })
            }
          })
        } else {
          if (!signingOut.current) {
            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider)
          }
        }
      });
    }
  }, [Loaded]);

  useEffect(() => {
    let sort = [], items = [];
    for (let i of Items)
      if (!Transmition.fechasubasta || Transmition.fechasubasta === i.fechasubasta)
        sort.push([(SortByCategories ? (i.categoria[0] || '') : '') + '-' + ((Number(i.numero) + 10000000) || '') + '-' + (i.titulo || ''), i]);
    for (let i of sort.sort())
      items.push(i[1])
    setSortedItems(items)
  }, [Items, SortByCategories, Transmition]);
  const saveItemProperty = (property, value) => {
    let val = {};
    val[property] = value;
    let docName = property === 'vivo' ? 'vivo' : 'JCarrizo';
    setDoc(doc(db, "Bollo", docName),
      val,
      { merge: true });
  }

  const setSoldItem = () => {
    let id = Transmition.loteActivo;
    for (let i of Items)
      if (i.id === id)
        setDoc(doc(db, "Bollo/JCarrizo/Lotes", id),
          { adjudicado: !i.adjudicado },
          { merge: true });
  }
  const capitalize = (t) => {
    t = t.toLowerCase();
    t = t.replaceAll('-', ' - ');
    t = t.split(' ');
    let u = [];
    for (let w of t) if (!!w) {
      if (w.length > 2) u.push(w[0].toUpperCase() + w.slice(1));
      else u.push(w);
    }
    return u.join(' ');
  }

  const normalizeStr = str => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/gmi, ' ')
      .replace(/\s+/g, ' ');
  }

  const showSearchItem = (item, search) => {
    if (!search)
      return true;
    let tx = normalizeStr(search).split(' '),
      str = normalizeStr(item.titulo + item.descripcion + item.numero + item.categoria.join(' ')).replaceAll(' ', ''),
      keep = true;
    for (let t of tx)
      if (keep && !!t)
        keep = str.includes(t);
    return keep;
  }


  return (
    <div id="App"
      className="remote"
    >
      <Buttons
        saveItemProperty={saveItemProperty}
        Transmition={Transmition}
        Live={Live}
        Casting={Casting}
        SortByCategories={SortByCategories}
        setSortByCategories={setSortByCategories}
        AuctionDates={AuctionDates}
        setSoldItem={setSoldItem}
        SearchItem={SearchItem}
        setSearchItem={setSearchItem}
      />
      <ItemsList
        saveItemProperty={saveItemProperty}
        Items={SortedItems}
        Transmition={Transmition}
        capitalize={capitalize}
        SearchItem={SearchItem}
        showSearchItem={showSearchItem}
      />
      <EstateList
        saveItemProperty={saveItemProperty}
        Estate={Estate}
        Transmition={Transmition}
        capitalize={capitalize}
        SearchItem={SearchItem}
        showSearchItem={showSearchItem}
      />
      <MessagesComp
        Messages={Messages}
      />
      <MConnected
        Users={Users}
      />
      <Connected
        Messages={Messages}
      />
    </div>
  );
}

export default Remote;
