import React, { useState, useEffect } from "react";
import { db, auth } from "./firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore"
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import setCss from "./setCss"

import Banner from "./show-banner.js";
import ActiveItemList from "./show-aitemslist.js";
import EstateList from "./show-estatelist.js";
import MessagesComp from "./show-messages.js";
import Data from "./show-data.js";

function Show(props) {
    const [Items, setItems] = useState([]);
    const [ActiveItem, setActiveItem] = useState({});
    const [ActiveProperty, setActiveProperty] = useState({});
    const [Estate, setEstate] = useState([]);
    const [Messages, setMessages] = useState([]);
    const [Transmition, setTransmition] = useState({});
    const [Loaded, setLoaded] = useState(false);

    useEffect(() => {
        setCss('/assets/show.css');
        setLoaded(true);
        if (Loaded) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    onSnapshot(
                        query(
                            collection(db, "Bollo/JCarrizo/Lotes"),
                            where("publicado", "==", true)
                        ),
                        (querySnapshot) => {
                            let items = [];
                            for (let doc of querySnapshot.docs)
                                items.push({
                                    ...doc.data(),
                                    id: doc.id,
                                });
                            setItems(items);
                        })
                    onSnapshot(
                        query(
                            collection(db, "Bollo/JCarrizo/Inmuebles"),
                            where("publicado", "==", true)
                        ),
                        (querySnapshot) => {
                            let items = [], sort = [];
                            for (let doc of querySnapshot.docs)
                                sort.push([
                                    (Number(doc.data().numero) + 1000000 || '') + (doc.data().titulo)
                                    , {
                                        ...doc.data(),
                                        id: doc.id,
                                    }]);
                            sort.sort();
                            for (let i of sort)
                                items.push(i[1]);
                            setEstate(items);
                        })
                    onSnapshot(
                        query(
                            collection(db, "Bollo/JCarrizo/Mensajes"),
                            where("ts", ">=", new Date(new Date().setHours(new Date().getHours() - 24))),
                        ),
                        (querySnapshot) => {
                            let items = [];
                            for (let doc of querySnapshot.docs)
                                items.push({
                                    ...doc.data(),
                                    id: doc.id,
                                });
                            setMessages(items);
                        })
                    onSnapshot(
                        doc(collection(db, "Bollo"), "JCarrizo"),
                        (querySnapshot) => {
                            setTransmition({ ...querySnapshot.data() })
                        })
                } else
                    signInAnonymously(auth);
            });
        }
    }, [Loaded]);

    useEffect(() => {
        for (let item of Items)
            if (item.id === Transmition.loteActivo)
                setActiveItem(item);
    }, [Items, Transmition]);

    useEffect(() => {
        for (let item of Estate)
            if (item.id === Transmition.loteActivo)
                setActiveProperty(item);
    }, [Estate, Transmition]);

    const galeriaFotos = tgt => {
        if (tgt.classList.contains('activo')) {
            tgt.classList.remove('activo');
            let next = (tgt.nextElementSibling || tgt.parentNode.firstElementChild)
            next.classList.add('activo');
            if (next.nodeName.toLowerCase() === 'video')
                next.play();
            if (!!next.src && next.src.includes('jpg')) tgt.parentNode.style.setProperty('--bgImg', 'url(' + next.src + ')');
        }
    }
    setInterval(() => {
        let activeVideo = document.querySelector('.activo .activo video.activo')
        for (let v of document.querySelectorAll('video'))
            if (v === activeVideo)
                v.play();
            else
                v.pause();
    }, 100)


    return (
        <div id="App"
            className="show"
            vista={Transmition.vista}
            adjudicado={ActiveItem.adjudicado ? 'si' : 'no'}
        >
            <Banner
                estate={ActiveProperty.id === Transmition.loteActivo}
            />
            {ActiveItem.id === Transmition.loteActivo ? (
                <ActiveItemList
                    ActiveItem={ActiveItem}
                    Transmition={Transmition}
                    galeriaFotos={galeriaFotos}
                />
            ) : ''}
            {ActiveProperty.id === Transmition.loteActivo ? (
                <EstateList
                    ActiveItem={ActiveProperty}
                    Transmition={Transmition}
                    galeriaFotos={galeriaFotos}
                />
            ) : ''}

            {ActiveItem.id === Transmition.loteActivo ? (
                <Data
                    ActiveItem={ActiveItem}
                />
            ) : ''}

            <img
                id="adjudicado"
                alt="adjudicado"
                src="assets/adjudicado.svg" />
            <MessagesComp
                Messages={Messages}
            />
        </div>
    );
}

export default Show;
