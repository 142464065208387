import React from 'react'

const Loader = (props) => {
    return (
        <div
            className="Loader"
        >
            <style>{`
body{
margin: 0
}
.App{
	height: 100vh;
	display: flex;
	flex-direction:column;
	background: url(/assets/fondo.svg) fixed top left, #fff;
}
.Loader{
	margin: auto;
	position: relative;
}
.Loader img{
    display: block;
    width: max(10vmin, 5em);
    height: max(10vmin, 5em);
}
.Loader::after {
	content: '';
	position: absolute;
	border: solid 0.5vh;
	border-width: max(.5vmin, .2em);
	border-color: #bd322d transparent;
	width: max(20vmin, 10em);
	height: max(20vmin, 10em);
	top: 50%;
	left: 50%;
	border-radius: 100%;
	transform-origin: center;
	animation: loader 1.5s ease infinite;
}
@keyframes loader{
0%{transform: translate(-50%, -50%) rotate(0deg);}
100%{transform: translate(-50%, -50%) rotate(360deg);}
}

						`}
            </style>
            <img
                src="/assets/logo.png"
                alt="Logo"
            />
        </div>
    );
}
export default Loader;
