import React, { useState, useEffect } from "react";
import { db, auth } from "./firebase.js";
import { doc, collection, onSnapshot, query, where, orderBy } from "firebase/firestore"
import { onAuthStateChanged } from "firebase/auth";
import setCss from "./setCss.js"
import MessagesComp from "./live-messages.js";
import Pics from "./live-pics.js";
import NewMessage from "./live-newmessage.js";
import Connected from "./messages-connected.js";

function Live() {
    const [Messages, setMessages] = useState([]);
    const [Transmition, setTransmition] = useState({});
    const [Items, setItems] = useState([]);
    const [Estate, setEstate] = useState("");
    const [ActiveUser, setActiveUser] = useState({});
    const [Users, setUsers] = useState([]);
    const [ActiveItem, setActiveItem] = useState({});
    const [Loaded, setLoaded] = useState(false);

    useEffect(() => {
        setCss('/assets/messages.css');
        setLoaded(true)
        if (Loaded) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    onSnapshot(doc(db, "Bollo/JCarrizo/Usuarios", user.uid), (userDoc) => {
                        if (userDoc.exists() && userDoc.data().admin) {
                            setActiveUser({ ...userDoc.data(), uid: user.uid });
                            onSnapshot(
                                query(
                                    collection(db, "Bollo/JCarrizo/Mensajes"),
                                    where("date", ">=", new Date().valueOf() - (12 * 60 * 60 * 1000)), //Last 12 hours
                                    orderBy("date", "asc")
                                ),
                                (querySnapshot) => {
                                    let msgs = [];
                                    querySnapshot.docs.forEach(msg => {
                                        msgs.push({
                                            id: msg.id,
                                            ...msg.data()
                                        })
                                    })
                                    setMessages(msgs.reverse())
                                })
                            onSnapshot(
                                query(
                                    collection(db, "Bollo/JCarrizo/Lotes"),
                                    where("publicado", "==", true),
                                ),
                                (querySnapshot) => {
                                    let items = [], sorted = [];
                                    querySnapshot.docs.forEach(doc => {
                                        sorted.push([100000 + Number(doc.data().numero), {
                                            ...doc.data(),
                                            id: doc.id,
                                        }])
                                    })
                                    for (let i of sorted.sort())
                                        items.push(i[1]);
                                    setItems(items);
                                })
                            onSnapshot(
                                query(
                                    collection(db, "Bollo/JCarrizo/Inmuebles"),
                                    where("numero", "!=", 0),
                                ),
                                (querySnapshot) => {
                                    setEstate(
                                        querySnapshot.docs.map((doc) => ({
                                            ...doc.data(),
                                            id: doc.id,
                                            select: 1000,
                                        }))
                                    )
                                })
                            onSnapshot(collection(db, "Bollo"), (snapshot) => {
                                for (let doc of snapshot.docs) {
                                    if (doc.id === 'JCarrizo') {
                                        setTransmition(doc.data())
                                    }
                                }
                            })

                            onSnapshot(
                                query(
                                    collection(db, "Bollo/vivo/Usuarios"),
                                    where("ts", ">=", new Date(new Date().setHours(new Date().getHours() - 2)))
                                ),
                                (snapshot) => {
                                    setUsers(
                                        snapshot.docs.map((doc) => ({
                                            ...doc.data(),
                                            id: doc.id,
                                        }))
                                    )
                                })

                        } else
                            window.location.href = '/';
                    })
                } else
                    window.location.href = '/';
            });
        }
    }, [Loaded]);

    useEffect(() => {
        if (Transmition.vista === 'inicial')
            setActiveItem({});
        else {
            for (let item of Items)
                if (item.id === Transmition.loteActivo)
                    setActiveItem({ ...item, property: false });
            for (let item of Estate)
                if (item.id === Transmition.loteActivo)
                    setActiveItem({ ...item, property: true });
        }
    }, [Items, Estate, Transmition]);


    //   const sendMessage = () => {
    //     setDoc(doc(db, "Bollo/JCarrizo/Mensajes", 'm' + new Date().valueOf() + '-u' + LoggedUser.uid), {
    //       ...MessageData,
    //       nombre: LoggedUser.nombre,
    //       uid: LoggedUser.uid,
    //       jc: LoggedUser.admin,
    //       ts: serverTimestamp(),
    //       date: new Date().valueOf(),
    //     });
    //     setMessageData({ ...MessageData, texto: '' })
    //   }

    //   useEffect(() => {
    //     if (!!AuctionDate && !!Items.length && !ItemsSorted) {
    //       setItemsSorted(true);
    //       let snap = [], sorted = []
    //       for (let item of Items)
    //         if (item.fechasubasta === AuctionDate) {
    //           let pos = Number(item.numero) || 1000;
    //           while (!!snap[pos]) pos++;
    //           item.select = item.numero;
    //           snap[pos] = item;
    //         }
    //       for (let item of snap)
    //         if (!!item)
    //           sorted.push(item);
    //       setItems(sorted)
    //     }
    //   }, [AuctionDate, Items, ItemsSorted]);

    return (
        <main id="App">
            <MessagesComp
                Messages={Messages}
                ActiveUser={ActiveUser}
            />
            <Pics
                ActiveItem={ActiveItem}
                Transmition={Transmition}
            />
            <NewMessage
                Items={Items}
                ActiveItem={ActiveItem}
                ActiveUser={ActiveUser}
                Transmition={Transmition}
            />
            <Connected
                Users={Users}
            />
        </main>
    );
}

export default Live;
