import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB6KlIfEtb4VbD-mLVl_Ad3PS22Hb3crqo",
  authDomain: "jose-carrizo.firebaseapp.com",
  projectId: "jose-carrizo",
  storageBucket: "jose-carrizo.appspot.com",
  messagingSenderId: "508071437490",
  appId: "1:508071437490:web:45e09ed7cc3fe4c01195ab"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBXYSV58hd6O9qIKWT2qTNAb-Vfq5UYvAw",
//   authDomain: "testing-2022-11.firebaseapp.com",
//   databaseURL: "https://testing-2022-11-default-rtdb.firebaseio.com",
//   projectId: "testing-2022-11",
//   storageBucket: "testing-2022-11.appspot.com",
//   messagingSenderId: "972837884619",
//   appId: "1:972837884619:web:9265071d2a0a92b013c14e"
// };

const app = initializeApp(firebaseConfig);
const authApp = getAuth(app);
authApp.languageCode = 'es';

export const
  db = getFirestore(app),
  storage = getStorage(app),
  auth = authApp;

