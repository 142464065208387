import React from 'react'

const PicturesContainer = (props) => {
    return (
        <div
            id="fotos"
            style={{
                gridArea: 'pics'
            }}
        >
            {props.ActiveItem.fotos.map((pic, i) => {
                return (
                    <div
                        key={props.ActiveItem + i}
                    >
                        <span
                            className="material-icons-outlined"
                            onClick={e => props.deletePic(pic)}
                        >
                            delete_sweep
                        </span>
                        {pic.toLowerCase().includes(".jpg") ? (
                            <img
                                src={pic}
                                onError={e => e.target.parentNode.remove()}
                                alt=''
                            />
                        ) : (
                            <video
                                src={pic}
                                onClick={e => e.target.play()}
                                alt=''
                            />
                        )}
                    </div>
                )
            })}
        </div >
    )
}
export default PicturesContainer;
