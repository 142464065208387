import React from 'react'
const Buttons = (props) => {
    let pics = [];
    if (!!props.ActiveItem.fotos && props.Transmition.vista !== 'inicial')
        for (let p of props.ActiveItem.fotos)
            if (p.includes('.jpg'))
                pics.push(p);
    return (
        <div
            id="fotos"
            style={{
                '--f': pics.length
            }}
        >
            <div className="over"></div>
            {pics.map(img => {
                return (
                    <img
                        key={img}
                        src={img}
                        alt='Img'
                        onError={e => e.target.remove()}
                        onLoad={e => e.target.style.setProperty('--ar', e.target.naturalWidth / e.target.naturalHeight)}
                        onClick={e => {
                            const previo = e.target.classList.contains('activo');
                            for (let j of e.target.parentNode.getElementsByTagName('img'))
                                j.classList.toggle('activo', 0);
                            e.target.classList.toggle('activo', !previo);
                        }}
                    />
                )
            })}
        </div>
    )
}
export default Buttons