import React from "react";

const Banner = (props) => {
    let render = true, firstPic = '';
    if (!props.ActiveItem.id || props.ActiveItem.id !== props.Transmition.loteActivo) render = false;
    if (!!props.Transmition.fechasubasta && props.Transmition.fechasubasta !== props.ActiveItem.fechasubasta) render = false;
    if (!!props.ActiveItem.fotos)
        for (let p of props.ActiveItem.fotos)
            if (!firstPic && p.includes('.jpg'))
                firstPic = p;
    return (
        <div
            id="galeria"
            className={render ? 'activo' : undefined}
        >
            {render ? (
                <div
                    key={props.ActiveItem.id}
                    item={props.ActiveItem.id}
                    lote={props.ActiveItem.numero || ''}
                    className='activo'
                    style={{
                        '--bgImg': 'url(' + firstPic + ')'
                    }}
                >
                    {props.ActiveItem.fotos.map((p, i) => {
                        if (p.includes('.jpg'))
                            return (
                                <img
                                    key={p}
                                    src={p}
                                    alt={p}
                                    className={i === 0 ? 'activo' : ''}
                                    onError={e => e.target.remove()}
                                    onLoad={e => {
                                        e.target.style.setProperty('--ar', e.target.naturalWidth / e.target.naturalHeight);
                                        if (e.target.parentNode.classList.contains('activo') && e.target === e.target.parentNode.firstElementChild)
                                            e.target.classList.add('activo');
                                    }}
                                    onAnimationIteration={e => props.galeriaFotos(e.target)}
                                />
                            )
                        else if (!!props.Transmition.cargarvideos)
                            return (
                                <video
                                    key={p}
                                    src={p}
                                    className={i === 0 ? 'activo' : ''}
                                    onError={e => e.target.remove()}
                                    muted='muted'
                                    onLoadedData={e => {
                                        e.target.playbackRate = .5;
                                        e.target.style.setProperty('--ar', e.target.videoWidth / e.target.videoHeight);
                                        if (e.target.parentNode.classList.contains('activo') && e.target === e.target.parentNode.firstElementChild)
                                            e.target.classList.add('activo');
                                    }}
                                    onPause={e => props.galeriaFotos(e.target)}
                                />
                            )
                    })}
                </div>
            ) : ''}
        </div>
    );
}
export default Banner;
