import React from 'react'

const ItemComp = (props) => {
    let pics = [], video = '';
    for (let p of props.ActiveItem.fotos)
        if (p.toLowerCase().includes('.jpg'))
            pics.push(p);
        else if (p.toLowerCase().includes('.mp4') && !video)
            video = p;
    const picAnimation = el => {
        if (el.classList.contains('activo')) {
            el.classList.toggle('activo', 0);
            el.parentNode.setAttribute('r', (Number(el.parentNode.getAttribute('r')) + 1) % 2);
            if (el.nextElementSibling) el.nextElementSibling.classList.toggle('activo', 1);
            else el.parentNode.firstElementChild.classList.toggle('activo', 1)
        }
    }
    return (
        <section
            id="Lote"
            busca="busca"
            key={'Details-' + props.ActiveItem.id}
        >
            <h2>
                {props.ActiveItem.titulo.toUpperCase() || ''}
            </h2>
            {props.ActiveItem.categoria ? (
                <p
                    style={{ paddingLeft: '5em' }}
                >
                    <b>
                        {
                            props.ActiveItem.categoria.sort().join(' - ')
                        }
                    </b>
                </p>
            ) : ''}
            {(video || pics.length) ? (
                <div
                    noslides="noslides"
                    novideo="novideo"
                    className="pictures"
                >
                    {video ? (
                        <video
                            autoPlay
                            loop
                            muted
                            onCanPlay={e => e.target.playbackRate = .75}
                            src={video}
                            onClick={e => {
                                document.fullscreen ?
                                    document.exitFullscreen()
                                    :
                                    e.target.requestFullscreen()
                            }}
                            onPause={e => e.target.play()}
                        />
                    ) : ''}
                    {pics.length ? (
                        <div
                            className={'slides' + (pics.length < 2 ? ' mono' : '')}
                        // onClick={e => {
                        //     document.fullscreen ?
                        //         document.exitFullscreen()
                        //         :
                        //         e.target.requestFullscreen()
                        // }}
                        >
                            {pics.map((p, i) =>
                            (<div
                                key={props.ActiveItem.id + 'p' + i}
                                className={i === 0 ? 'activo' : ''}
                                onAnimationEnd={e => picAnimation(e.target)}
                            >
                                <div
                                    style={{ backgroundImage: 'url(' + p + ')' }}
                                >
                                </div>
                            </div>)
                            )}
                        </div>
                    ) : ''}
                </div>
            ) : ''}
            <p>
                {(props.ActiveItem.section === 'subastas' ? ('Lote ' + (props.ActiveItem.numero || '') + ': ') : '') + (props.ActiveItem.descripcion || '')}
            </p>
            {(!props.ActiveItem.vdirecta && props.ActiveItem.base) ?
                (<p><br />{
                    'BASE: $'
                    + props.ActiveItem.base
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                    + '.-'
                }
                </p>) : ''}
            {(props.ActiveItem.vdirecta || props.ActiveItem.base) ? (
                <p>
                    {
                        props.ActiveItem.vdirecta ?
                            'Recibimos su oferta se la pasamos al propietario; si acepta cerramos el negocio.-' :
                            'Recibimos su oferta para abrir la subasta. Si no hay interesados por la base abriremos la subasta con las ofertas existentes. Ultima oferta sujeta a la aprobacion del propietario para adjudicar el bien.-'
                    }
                </p>
            ) : ''}
            {props.ActiveItem.coordenadas ? (
                <iframe
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src={'https://maps.google.com/maps?t=k&z=14&output=embed&q=' + props.ActiveItem.coordenadas}
                    title='Ubicación del Inmueble'
                />
            ) : ''}
            <div

                className="contacto">
                {/* <div

                    className="ofertas">
                    <div>
                        Oferta Actual:
                        <span

                            className="ofertaactual"
                        >
                        </span>
                    </div>
                    <div

                        className="ofertaproxWrap"
                    >
                        <span

                            className="reduceOffer"
                        >
                            -
                        </span>
                        <a
                            rel="noreferrer"
                            href={'https://wa.me/5493547448449?text=Hola,%20escribo%20para%20ofertar%20$%oferta%%20por%20' + encodeURIComponent(props.ActiveItem.titulo.toUpperCase() || '') + '%0A' + encodeURIComponent(window.location.href) + '%0A'}
                            target="_blank"
                        >
                            Ofertar $ <span
                                className="ofertaprox"
                            >
                            </span>
                        </a>
                        <span
                            className="increaseOffer"
                        >
                            +
                        </span>
                    </div>
                    <div>
                        Cierre de Ofertas Web:
                        <span
                            className="cierreofertas"
                        >
                        </span>
                    </div>
                    <div>
                        Cierre de Subasta:
                        <span
                            className="cierrevivo"
                        >
                        </span>
                    </div>
                </div> */}
                <div
                    className="consultas"
                >
                    <a
                        className="whatsapp"
                        rel="noreferrer"
                        href={'https://wa.me/5493547448449?text=Hola,%20escribo%20para%20consultar%20por%20' + encodeURIComponent(props.ActiveItem.titulo.toUpperCase() || '') + '%0A' + encodeURIComponent(window.location.href) + '%0A'}
                        target="_blank"
                    >
                        Consultas
                    </a>
                    <a
                        className="whatsapp"
                        rel="noreferrer"
                        href={'https://wa.me/?text=' + encodeURIComponent(props.ActiveItem.titulo.toUpperCase() || '') + '%0A' + encodeURIComponent(window.location.href) + '%0A'}
                        target="_blank">
                        Compartir
                    </a>
                </div>
            </div>
        </section>
    );
}
export default ItemComp;
