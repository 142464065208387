import React from "react";

const Banner = (props) => {
    return (
        <div id="mensajes">
            {props.Messages.map(msg => {
                return (
                    <div
                        key={msg.id}
                        className={msg.jc ? 'jc ' : ''}
                        msj-id={msg.id}
                    >
                        <span className="nombre">{msg.nombre}</span>
                        <span className="texto">{msg.texto}</span>
                        <span
                            className={'lote' + (msg.lote === '1000' ? ' material-icons' : '') + (msg.lote === '' ? ' saludo' : '')}
                        >
                            {msg.lote === '1000' ? 'home' : msg.lote}
                        </span>
                        <span className="hora" data-bollo-hora="ts">{msg.ts.toDate().toLocaleString().split(' ')[1]}</span>
                    </div>
                )
            })}
        </div>
    );
}
export default Banner;
