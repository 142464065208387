import React, { useState, useEffect, useRef } from "react";
import { db } from "./firebase";
import { collection, onSnapshot, query, where, orderBy } from "firebase/firestore"


const Nav = (props) => {
    const [Users, setUsers] = useState({});
    const [Messages, setMessages] = useState({});
    const [MsgsDate, setMsgsDate] = useState('');
    const [ExportMessages, setExportMessages] = useState(false);
    const listener = useRef({ msgs: {} });

    const expdoc = (cuerpo, nombre) => {
        let sourceHTML = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>" + nombre + "</title></head><body>" + cuerpo + "</body></html>";
        var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
        var fileDownload = document.createElement("a");
        fileDownload.href = source;
        fileDownload.download = nombre + '.rtf';
        fileDownload.click();
        fileDownload.remove();
    }

    const getItemsList = (Items) => {
        const cleanText = (text, lcase = false) => {
            text = text.trim();
            return text.charAt(0).toUpperCase() + lcase ? text.slice(1).toLowerCase() : text.slice(1);
        }

        let listadoSubCats = document.createElement('div');
        listadoSubCats.innerHTML = '<h1 lote="0"><center>LOTEO DE BIENES</center></h1>';
        let listadoSubCli = document.createElement('div');
        listadoSubCli.innerHTML = '<h1 lote="0"><center>LOTEO DE BIENES</center></h1>';
        let loteoCats = { 'Sin Categoría': [0] };
        for (let l in Items) if (!Items[l].adjudicado && Number(Items[l].numero) > 0 && Items[l].publicado) {
            let cat = (Items[l].categoria && Items[l].categoria[0]) ?
                Items[l].categoria[0] : 'Sin Categoría';
            if (!loteoCats[cat]) loteoCats[cat] = [];
            loteoCats[cat][Number(Items[l].numero)] = Items[l];
        }
        let categorias = [...new Set(['Sin Categoría', ...Object.keys(loteoCats).sort()])];
        for (let cat of categorias) {
            let titulo = document.createElement('h2'),
                items = document.createElement('div'),
                itemsCli = document.createElement('div');
            titulo.innerHTML = '<br/>' + cat.toUpperCase();
            for (let l of loteoCats[cat])
                if (l && l.numero) {
                    let linea = document.createElement('p'),
                        lineaCli = document.createElement('p');
                    linea.setAttribute('lote', l.numero);
                    linea.innerHTML = '<big><strong>Lote ' + l.numero + ': ' +
                        cleanText(l.titulo, true) +
                        '.–</strong></big> ' +
                        cleanText(l.descripcion) +
                        '<br>' +
                        ((!!l.base && Number(l.base) > 0) ? ('Precio Base: <big><strong>$' + l.base + '</strong></big>') : ('<big><strong>Sin Base</strong></big>')) +
                        ((!!l.preciovdirecta && Number(l.preciovdirecta) > 0) ? (' - Precio Venta Directa: <big><strong>$' + l.preciovdirecta + '</strong></big>') : '');
                    lineaCli.innerHTML = '<big><strong>Lote ' + l.numero + ': ' +
                        cleanText(l.titulo, true) +
                        '.–</strong></big> ' +
                        cleanText(l.descripcion);
                    items.appendChild(linea);
                    itemsCli.appendChild(lineaCli);
                }
            if (!!items.innerText) {
                let tituloCli = titulo.cloneNode(true);
                listadoSubCats.appendChild(titulo);
                listadoSubCats.appendChild(items);
                listadoSubCli.appendChild(tituloCli);
                listadoSubCli.appendChild(itemsCli);
            }
        }
        expdoc(listadoSubCats.innerHTML, 'LOTEO DE BIENES por Categoria');
        let listadoSubOrd = document.createElement('div');
        listadoSubOrd.innerHTML = '<h1 lote="0"><center>LOTEO DE BIENES</center></h1>';
        let loteoOrd = [];
        for (let l in Items)
            if (!Items[l].adjudicado && Number(Items[l].numero) > 0 && Items[l].publicado)
                loteoOrd[Number(Items[l].numero)] = Items[l];
        for (let l of loteoOrd)
            if (!!l && !!l.numero) {
                let linea = document.createElement('p');
                linea.setAttribute('lote', l.numero);
                linea.innerHTML = '<big><strong>Lote ' +
                    l.numero + ': ' +
                    cleanText(l.titulo, true) +
                    '.–</strong></big> ' +
                    cleanText(l.descripcion) +
                    '<br>' +
                    ((!!l.base && Number(l.base) > 0) ? ('Precio Base: <big><strong>$' + l.base + '</strong></big>') : ('<big><strong>Sin Base</strong></big>')) +
                    ((!!l.preciovdirecta && Number(l.preciovdirecta) > 0) ? (' - Precio Venta Directa: <big><strong>$' + l.preciovdirecta + '</strong></big>') : '') +
                    (!!l.categoria && !!l.categoria[0]) ? ('<br>Categoría: ' + l.categoria[0]) : '';
                listadoSubOrd.appendChild(linea);
            }
        expdoc(listadoSubOrd.innerHTML, 'LOTEO DE BIENES ordenado');
        expdoc(listadoSubCli.innerHTML, 'LOTEO DE BIENES');
    }

    const auctionMsgs = (e) => {
        if (e.type.toLowerCase() === 'keyup' && e.keyCode === 13 && Number(new Date(e.target.value))) {
            setMsgsDate(e.target.value);
            setMessages({});
            setExportMessages(true);
        }
    }

    useEffect(() => {
        if (!listener.current.users && !!MsgsDate)
            listener.current.users = onSnapshot(
                collection(db, "Bollo/vivo/Usuarios"),
                (querySnapshot) => {
                    let usr = {};
                    for (let doc of querySnapshot.docs)
                        usr[doc.id] = {
                            ...doc.data()
                        }
                    setUsers(usr)
                })
        const from = new Date(Number(new Date(MsgsDate)) + 10800000),
            to = new Date(Number(from) + 86400000);
        if (!listener.current.msgs[MsgsDate] && Number(from)) {
            listener.current.msgs[MsgsDate] = onSnapshot(
                query(
                    collection(db, "Bollo/JCarrizo/Mensajes"),
                    where("ts", ">=", from),
                    where("ts", "<=", to),
                    orderBy("ts", "asc")
                ),
                (querySnapshot) => {
                    let msgs = {};
                    for (let doc of querySnapshot.docs)
                        msgs[doc.id] = {
                            ...doc.data()
                        }
                    setMessages(msgs)
                })
        }
    }, [MsgsDate]);

    useEffect(() => {
        if (!!Object.keys(Users).length && !!Object.keys(Messages).length && ExportMessages) {
            let listadoMensajes = document.createElement('div');
            listadoMensajes.innerHTML = '<h1><center>Listado de Mensajes del día ' + new Date(Number(new Date(MsgsDate)) + 10800000).toLocaleDateString() + '</center></h1>';
            let tablaMensajes = document.createElement('table');
            listadoMensajes.appendChild(tablaMensajes);
            for (let i in Messages) {
                let m = Messages[i]
                let linea = document.createElement('tr'),
                    msje = document.createElement('td'),
                    usr = document.createElement('td'),
                    mwrap = document.createElement('div'),
                    text = document.createElement('span'),
                    wrap = [new Date(m.ts.seconds * 1000).toLocaleTimeString(), (m.nombre || '')];
                if (!!m.lote)
                    wrap.push('Lote ' + m.lote);
                linea.appendChild(msje);
                linea.appendChild(usr);
                msje.appendChild(mwrap);
                msje.appendChild(text);
                mwrap.innerHTML = '<small>' + wrap.join(' - ') + '</small>';
                text.innerText = m.texto;
                msje.style = 'padding: 0.5em 1em;';
                let u = [];
                if(!!Users[m.uid]){
                    if (!!Users[m.uid].nombre)
                        u.push(Users[m.uid].nombre);
                    if (!!Users[m.uid].email)
                        u.push('<a href="mailto:' + Users[m.uid].email + '">' + Users[m.uid].email + '</a>');
                    if (!!Users[m.uid].telefono)
                        u.push(Users[m.uid].telefono);
                }
                usr.innerHTML = '<small>' + u.join('<br/>') + '</small>';
                tablaMensajes.appendChild(linea);
            }
            expdoc(listadoMensajes.innerHTML, 'Listado de Mensajes ' + new Date(Number(new Date(MsgsDate)) + 10800000).toLocaleDateString().replaceAll('/', '-'));
            setExportMessages(false)
        }
    }, [Users, Messages, ExportMessages, MsgsDate]);

    return (
        <nav
            style={{
                gridArea: 'nav'
            }}
        >
            <div>
                <span>
                    Próxima Subasta:
                </span>
                <input
                    type="text"
                    id="proxsubasta"
                    defaultValue={props.AuctionDate}
                />
                <input
                    type="button"
                    value="Grabar"
                    id="fechaSubasta"
                    onClick={() => props.saveAuctionDate(document.getElementById('proxsubasta').value)}
                />
            </div>
            <div>
                <input
                    type="button"
                    value="Exportar Listados"
                    id="exportarListados"
                    onClick={e => getItemsList(props.Items)}
                />
            </div>
            <label>
                <span>
                    Listado de Mensajes
                </span>
                <input
                    type="date"
                    id="mensajesSubasta"
                    onKeyUp={e => auctionMsgs(e)}
                />
            </label>
        </nav>
    );
}
export default Nav;
