import React from 'react'

const Categories = (props) => {
    const renderCategory = (cat, sale = false) => {
        if (cat === ' ') cat = 'SIN CATEGORIZAR';
        let r = Number(!sale) + ',' + props.normalizeStr(cat).replaceAll(' ', '')
        return (
            <li
                key={r}
                onClick={e => {
                    props.setSearch('');
                    if (document.querySelector('[cat="' + r + '"]'))
                        document.querySelector('[cat="' + r + '"]').scrollIntoView();
                }}
            >
                <h2>
                    {props.toTitleCase(cat)}
                </h2>
            </li>
        )
    }
    return (
        <nav
            className="categorias"
        >
            <div>
                {props.Categories.auction.length ? (<h1>Subasta</h1>) : ''}
                {props.Categories.auction.length ? (
                    <ul navcat="1">
                        {props.Categories.auction.map(cat => renderCategory(cat))}
                    </ul>
                ) : ''}
                {props.Categories.sale.length ? (<h1>Venta Directa</h1>) : ''}
                {props.Categories.sale.length ? (
                    <ul navcat="0">
                    </ul>
                ) : ''}

            </div>
        </nav>
    );
}

const Section = (props) => {

    const Grid = (cat, sale) => {
        let r = Number(!sale) + ',' + props.normalizeStr(cat).replaceAll(' ', ''),
            n = 0,
            ItemsList = [];
        for (let id in props.Items)
            if (props.Items[id].vdirecta === sale)
                if (props.Items[id].categoria.includes(cat)) {
                    let pic = '';
                    for (let p of props.Items[id].fotos)
                        if (!pic && p.toLowerCase().includes('.jpg'))
                            pic = p;
                    ItemsList.push([props.Items[id].titulo.toUpperCase(), id, pic, !Array.isArray(props.ItemsList) || props.ItemsList.includes(id)]);
                    if (!Array.isArray(props.ItemsList) || props.ItemsList.includes(id)) n++;
                }
        ItemsList.sort();
        return (
            <div
                key={r}
                cat={r}
                className={'category' + (Array.isArray(props.ItemsList) ? ' search' : '') + (n === 0 ? ' searchHidden' : '')}
            >
                {cat !== ' ' ? (
                    <h2>
                        {cat.toUpperCase()}
                    </h2>
                ) : ''}
                {ItemsList.map(item => {
                    let key = 'item,' + item[1] + ',' + r;
                    return (
                        <a
                            href={'#' + item[1]}
                            key={key}
                            className={!item[3] ? 'searchHidden' : ''}
                        >
                            <img
                                className='loading'
                                src={item[2]}
                                onError={e => e.target.parentNode.remove()}
                                onLoad={e => e.target.classList.remove('loading')}
                                alt=''
                            />
                            <h3>
                                {item[0]}
                            </h3>
                        </a>
                    )
                })}
            </div>
        )
    }

    return (
        <div
            id="subastas"
        >
            <section>
                <h1>
                    Subastas
                </h1>
                <p>
                    Servicio destinado a vender en pública subasta, en forma On Line, todo aquello que en una casa o empresa, ya no se usa.-
                </p>
                <p>
                    Mas de treinta años en el ejercicio de la Profesión de Martillero, avalan este proyecto.-
                </p>
                <p>
                    Contamos con servicio de comisionistas para retirar y entregar los bienes.-
                </p>
            </section>
            <section
                id="vivo"
            >
                <h1>
                    Subastas online en Vivo
                </h1>
                <p>
                    Para estar al tanto de futuras subastas, te invitamos a sumarte a
                    <a
                        href="https://wa.me/+5493547448449"
                        target="_blank"
                        rel="noreferrer"
                    >
                        contactanos por
                        <svg
                            style={{
                                height: '1.5em',
                                verticalAlign: 'bottom'
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 293.5 293.5"
                        >
                            <path
                                d="M223.777,70.979c-19.623-19.646-45.719-30.47-73.522-30.482 c-57.288,0-103.914,46.623-103.937,103.929c-0.007,18.318,4.778,36.198,13.874,51.961l-14.745,53.858l55.098-14.453 c15.181,8.28,32.273,12.645,49.668,12.651h0.043c57.282,0,103.912-46.629,103.936-103.936 C254.202,116.737,243.4,90.624,223.777,70.979z M150.256,230.89h-0.035c-15.501-0.006-30.705-4.171-43.968-12.042l-3.155-1.871 l-32.696,8.576l8.727-31.878l-2.054-3.27c-8.647-13.753-13.215-29.65-13.208-45.974c0.019-47.63,38.772-86.38,86.424-86.38 c23.073,0.008,44.764,9.005,61.074,25.335c16.31,16.329,25.286,38.033,25.277,61.116 C236.623,192.136,197.87,230.89,150.256,230.89z M197.641,166.189c-2.597-1.299-15.364-7.582-17.745-8.449 c-2.38-0.865-4.112-1.299-5.843,1.301c-1.731,2.6-6.709,8.449-8.224,10.183c-1.515,1.732-3.03,1.95-5.626,0.649 c-2.598-1.299-10.965-4.042-20.885-12.89c-7.72-6.886-12.932-15.39-14.447-17.991c-1.515-2.6-0.162-4.005,1.139-5.3 c1.168-1.164,2.597-3.034,3.896-4.55s1.731-2.6,2.597-4.333s0.433-3.25-0.217-4.549c-0.649-1.301-5.843-14.084-8.007-19.284 c-2.108-5.063-4.249-4.378-5.843-4.458c-1.513-0.075-3.246-0.092-4.978-0.092c-1.731,0-4.544,0.65-6.925,3.25 c-2.38,2.6-9.089,8.883-9.089,21.666c0,12.783,9.305,25.131,10.604,26.865c1.298,1.733,18.313,27.964,44.364,39.214 c6.195,2.676,11.033,4.273,14.805,5.471c6.222,1.977,11.883,1.697,16.357,1.029c4.99-0.746,15.365-6.283,17.529-12.349 c2.164-6.067,2.164-11.267,1.515-12.35C201.969,168.14,200.238,167.49,197.641,166.189z"
                            />
                        </svg>
                        Whatsapp
                    </a>
                </p>
            </section>
            {props.NextAuctionDate ? (
                <section>
                    <h1>
                        Próxima Subasta <span
                            id="proxSubasta"
                        >
                            {props.NextAuctionDate}
                        </span>
                    </h1>
                </section>
            ) : ''}
            {props.Categories.auction.length ? (
                <section
                    id="grillaLotes"
                >
                    <div
                        className="grilla"
                    >
                        {props.Categories.auction.map(cat => Grid(cat, false))}
                    </div>
                </section>
            ) : ''}
            {props.Categories.sale.length ? (
                <section
                    id="grillaDirecta"
                >
                    <h1>
                        Lotes en Venta Directa
                    </h1>
                    <div
                        className="grilla"
                    >
                        {props.Categories.sale.map(cat => Grid(cat, true))}
                    </div>
                </section>
            ) : ''}
        </div>
    );
}
export const
    Auctions = Section,
    AuctionCategories = Categories
