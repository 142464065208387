import React, { useState } from "react";
import { Timestamp } from "firebase/firestore";


const Banner = (props) => {
    const [Time, setTime] = useState('');
    if (!Time) {
        const updTime = () => {
            const fecha = Timestamp.now().toDate(),
                mes = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];
            fecha.setSeconds(fecha.getSeconds() + 35);
            setTime(
                (fecha.getDate() < 10 ? "0" : "") + fecha.getDate() + " de " + mes[fecha.getMonth()] + " de " + fecha.getFullYear() + " " + fecha.toLocaleTimeString()
            )
        }
        window.setInterval(updTime, 1000);
        updTime();
    }


    return (
        <div
            id="banner"
        >
            <img
                id="logo"
                alt="Logo Martillero"
                src={props.estate ? "assets/logo-si.png" : "assets/logo.png"}
            />
            <img
                id="logosi"
                alt="Logo Inmoviliario"
                src="assets/logo-si.png"
            />
            <div
                id="contacto"
            >
                <p
                    id="mpmart"
                >
                    Matrícula Profesional: 05-0570
                </p>
                <p
                    id="mpinmob"
                >
                    Matrícula Profesional: 04-1946
                </p>
                <p>Consultas: 3547-448449</p>
                <p>Facebook: FB/josecarrizosubastas</p>
                <p id="fechahora">{Time}</p>
            </div>
        </div>
    );
}
export default Banner;
