import React from 'react'

const Loading = () => {
    return (
        <section id="cargando">
            <main>
                <img
                    className="logo"
                    src="/assets/logo.png"
                    alt=''
                />
                <p>Cargando</p>
            </main>
        </section>
    );
}
export default Loading;