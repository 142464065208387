import React from 'react'

const Footer = (props) => {
    return (
        <footer
            style={{
                textAlign: 'center'
            }}
        >
            <a
                style={{
                    textDecoration:'none',
                    color:'currentcolor'
                }}
                id="bollo"
                href="https://bollo.com.ar"
                target="_blank"
                rel="noreferrer"
            >
                Copyright 2024
                <img
                    src="https://bollo.com.ar/safari-pinned-tab.svg"
                    alt="Logo desarrollador Web"
                />
                Bollo | Servicios Web
            </a>
        </footer>
    );
}
export default Footer;
