import React from 'react'

const Connected = (props) => {
    let connected = {}, connectedSort = [], connectedArray = [];
    for (let m of props.Messages) {
        if (!connected[m.uid]) connected[m.uid] = { lastMsg: 0, names: {} }
        connected[m.uid].lastMsg = Math.max(connected[m.uid].lastMsg, m.ts.toDate());
        if (!connected[m.uid].names[m.nombre]) connected[m.uid].names[m.nombre] = {}
        connected[m.uid].names[m.nombre].lastMsg = Math.max(connected[m.uid].names[m.nombre].lastMsg || 0, m.ts.toDate());
    }
    for (let uid in connected)
        for (let name in connected[uid].names) {
            connectedSort.push([
                connected[uid].lastMsg + uid + connected[uid].names[name].lastMsg + name,
                {
                    uid: uid,
                    name: name,
                    date: connected[uid].names[name]
                }
            ])
        }
    connectedSort.sort().reverse();
    connectedSort.forEach((c, pos) => {
        if (!connected[c[1].uid].position) connected[c[1].uid].position = pos
        connected[c[1].uid].names[c[1].name].position = pos
    })
    for (let uid in connected) {
        connectedArray[connected[uid].position] = { uid: uid };
        if (Object.keys(connected[uid].names).length === 1) {
            for (let name in connected[uid].names) {
                connectedArray[connected[uid].position].name = name;
                connectedArray[connected[uid].position].lastMsg = connected[uid].names[name].lastMsg;
            }
        } else {
            connectedArray[connected[uid].position].names = []
            for (let name in connected[uid].names) {
                connectedArray[connected[uid].position].names[connected[uid].names[name].position] = {
                    name: name,
                    lastMsg: connected[uid].names[name].lastMsg
                }
            }
        }
    }
    return (
        <section id="participantes">
            <h1>Participan ({Object.keys(connected).length})</h1>
            <ConnectedRender
                arr={connectedArray}
            />
        </section>
    )
}

const ConnectedRender = (props) => {
    return (
        <ul>
            {props.arr.map(uid => {
                return (
                    <li
                        key={'connected' + (uid.uid || uid.name)}
                    >
                        {uid.name ? (
                            <span>{uid.name} <small>{new Date(uid.lastMsg).toLocaleString()}</small></span>
                        ) : ''}
                        {uid.names ? (
                            <ConnectedRender
                                arr={uid.names}
                            />
                        ) : ''}
                    </li>
                )
            })}
        </ul>
    )
}
export default Connected
