import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "./firebase";
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore"
import UserNav from "./live-nav";
import { AuctionCategories } from "./root-auctions";
import { EstateCategoriesComp } from "./root-estate.js";


const Nav = (props) => {
    const [LoggedUser, setLoggedUser] = useState("");
    const listener = useRef({});

    useEffect(() => {
        if (!listener.current.auth)
            listener.current.auth = onAuthStateChanged(auth, (user) => {
                if (user) {
                    onSnapshot(doc(db, "Bollo/JCarrizo/Usuarios", user.uid), (userDoc) => {
                        if (userDoc.exists())
                            setLoggedUser({ ...userDoc.data(), uid: user.uid });
                        else
                            setLoggedUser(false);
                    })
                } else
                    setLoggedUser(false);
            });
    }, [])



    const menuToggle = () => {
        document.body.classList.toggle("menu")
    }
    return (
        <header>
            <a
                className="logo"
                href="#"
                onDoubleClick={(e) => {
                    if (!props.Hash) {
                        const provider = new GoogleAuthProvider();
                        signInWithPopup(auth, provider)
                        e.preventDefault()
                    }
                }}
            >
                <img
                    src="/assets/logo.png"
                    alt="Logo"
                />
            </a>
            <a
                className="cpmcp"
                href="http://colmart.dyndns.org:8090/movil/Buscar_Colegiado_Publico.asp?buscar=041946"
                target="_blank"
                rel="noreferrer"
            >
                <img
                    src="/assets/cpmcp.jpg"
                    alt="Logo Colegio de Martilleros Públicos"
                />
                <span>
                    Carrizo José Nestor
                    <br />
                    Colegio Profesional de Martilleros y Corredores Públicos de la Provincia de Córdoba
                </span>
            </a>
            {(!props.Hash && !!LoggedUser) ? (
                <UserNav
                    admin={LoggedUser.admin || false}
                    user={LoggedUser}
                    setLoggedUser={setLoggedUser}
                    autoSignIn={false}
                />
            ) : ''
            }
            <nav
                className="menu"
            >
                <div
                    id="contacto"
                >
                    <a
                        className="whatsapp"
                        rel="noreferrer"
                        href="https://wa.me/5493547448449"
                        target="_blank"
                    >
                        3547 44-8449
                    </a>
                    <a
                        className="email"
                        href="mailto:carrizojosenestor@gmail.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        carrizojosenestor@gmail.com
                    </a>
                </div>
                <div
                    id="links"
                >
                    {props.renderEstate ? (
                        <a
                            href="#serviciosinmoviliarios"
                        >
                            Servicios Inmobiliarios
                        </a>
                    ) : ''}
                    {props.renderItems ? (
                        <a
                            href="#subastas"
                        >
                            Subastas
                        </a>
                    ) : ''}
                    <a
                        href="/vivo"
                        className="vivo"
                    >
                        En Vivo
                    </a>
                    {(props.ActiveItem.section === 'subastas' && props.renderItems) ? (
                        <AuctionCategories
                            Categories={props.Categories}
                            normalizeStr={props.normalizeStr}
                            toTitleCase={props.toTitleCase}
                            setSearch={props.setSearch}
                        />
                    ) : ''}
                    {(props.ActiveItem.section === 'serviciosinmoviliarios' && props.renderEstate) ? (
                        <EstateCategoriesComp
                            Categories={props.Categories}
                            normalizeStr={props.normalizeStr}
                            toTitleCase={props.toTitleCase}
                            setSearch={props.setSearch}
                            Search={props.Search}
                            CatSearch={props.CatSearch}
                            setCatSearch={props.setCatSearch}
                        />
                    ) : ''}
                </div>
                <div
                    id="busqueda"
                    className={props.Search ? 'active' : ''}
                >
                    <span
                        className="material-icons"
                    >
                        search
                    </span>
                    <input
                        className="searchbox"
                        type="text"
                        placeholder="Buscar"
                        value={props.Search}
                        onChange={e => props.setSearch(e.target.value)}
                    />
                    <button type="button"
                        className="material-icons cleanSearchbox"
                        onClick={e => props.setSearch('')}
                    >
                        backspace
                    </button>
                </div>
                <svg
                    id="menu"
                    viewBox="0 0 390 280"
                    width="51"
                    height="42"
                    onClick={menuToggle}
                >
                    <path
                        d="m373 36h-240c-200 0-100 320 20 200 80-80 220-220 220-220"
                    />
                    <path
                        d="m373 136h-240"
                    />
                    <path
                        d="m373 244h-240c-200 0-100-320 20-200 80 80 220 220 220 220"
                    />
                </svg>
            </nav>
        </header >
    );
}
export default Nav;
