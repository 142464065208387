import React from 'react'
const Buttons = (props) => {
    return (
        <section
            className='doble'
            id="botones"
        >
            <section
                id="nav"
            >
                <label>
                    Fecha Subasta:
                    <select
                        id="fechasubasta"
                        onChange={(e) => props.saveItemProperty('fechasubasta', e.target.value)}
                        value={props.Transmition.fechasubasta || ''}
                    >
                        <option
                            value=''
                        >
                            Todos
                        </option>
                        {props.AuctionDates.map(date => {
                            return <option
                                key={'auctionDate' + date}
                                value={date}
                            >
                                {date}
                            </option>
                        })}
                    </select>
                </label>
                <div
                    onClick={() => props.setSortByCategories(!props.SortByCategories)}
                >
                    {'Ordenado por ' + (props.SortByCategories ? 'Categoría' : 'Lote')}
                </div>
            </section>
            <section>
                <h1>
                    Cambios
                </h1>
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <div
                        onClick={() => {
                            let active = document.getElementById(props.Transmition.loteActivo);
                            if (active && active.previousElementSibling && active.previousElementSibling.id)
                                props.saveItemProperty('loteActivo', active.previousElementSibling.id)
                        }}
                    >
                        &lt;
                    </div>
                    <div
                        onClick={() => props.setSoldItem()}
                    >
                        Adjudicado
                    </div>
                    <div
                        onClick={() => {
                            let active = document.getElementById(props.Transmition.loteActivo);
                            if (active && active.nextElementSibling && active.nextElementSibling.id)
                                props.saveItemProperty('loteActivo', active.nextElementSibling.id)
                        }}
                    >
                        &gt;
                    </div>
                </div>
            </section>
            <section id="vistas"
            >
                <h1>
                    Vistas
                </h1>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="vista"
                            onChange={() => props.saveItemProperty('vista', 'inicial')}
                            checked={props.Transmition.vista === 'inicial'}
                        />
                        Inicial
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="vista"
                            onChange={() => props.saveItemProperty('vista', 'data')}
                            checked={props.Transmition.vista === 'data'}
                        />
                        Info Lote
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="vista"
                            onChange={() => props.saveItemProperty('vista', 'mixto')}
                            checked={props.Transmition.vista === 'mixto'}
                        />
                        Mixto
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="vista"
                            onChange={() => props.saveItemProperty('vista', 'detalle')}
                            checked={props.Transmition.vista === 'detalle'}
                        />
                        Detalle
                    </label>
                </div>
            </section>
            <section id="presentacion"
            >
                <h1>
                    Presentacion
                </h1>
                <label
                    style={{
                        fontWeight: (props.Live) ? 'bold' : 'normal',
                    }}
                >
                    <input
                        type="checkbox"
                        checked={props.Live || false}
                        onChange={(e) => props.saveItemProperty('vivo', e.target.checked)}
                    />
                    Vivo
                </label>
                {props.Casting ? (<div><b>Transmitiendo</b></div>) : ''}
            </section>
            <section>
                <h1>Búsqueda</h1>
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <input
                        type='text'
                        value={props.SearchItem}
                        onChange={(e) => props.setSearchItem(e.target.value)}
                    />
                    <input
                        type='button'
                        value='<-'
                        onClick={() => props.setSearchItem('')}
                    />
                </div>
            </section>
        </section>
    )
}
export default Buttons
