import React from 'react'
import { useRouteError, redirect } from 'react-router-dom'
const Error = () => {
    const error = useRouteError();
    console.log(error);
    redirect("/");
    return (
        <>
            {window.location.href = '/'}
        </>
    )
}
export default Error