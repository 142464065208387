import React from 'react'

const Messages = (props) => {
    return (
        <section
            id="mensajes"
            className='doble'
            style={{width:'100%'}}
        >
            <h1>Mensajes</h1>
            {props.Messages.map(msg => {
                return (
                    <div
                        key={'msg' + msg.id}
                        msj-id={msg.id}
                        className={(msg.jc?'jc ':'')}
                    >
                        <span className="nombre">{msg.nombre}</span>
                        <span className="texto">{msg.texto}</span>
                        <span className="hora" data-bollo-hora="ts">{msg.ts.toDate().toLocaleString()}</span>
                    </div>
                )
            })}
        </section>
    )
}
export default Messages
