import React, { useState, useEffect } from 'react'
import { db } from "./firebase.js";
import { doc, setDoc, serverTimestamp } from "firebase/firestore"

const NewMessage = (props) => {
    const [MessageData, setMessageData] = useState('');
    const [ItemSelector, setItemSelector] = useState(-1);
    const [ItemInShow, setItemInShow] = useState(-1);
    const sendMessage = () => {
        setDoc(doc(db, "Bollo/JCarrizo/Mensajes", 'm' + new Date().valueOf() + '-u' + props.ActiveUser.uid), {
            texto: MessageData,
            lote: ItemSelector,
            nombre: props.ActiveUser.nombre,
            uid: props.ActiveUser.uid,
            jc: props.ActiveUser.admin,
            ts: serverTimestamp(),
            date: new Date().valueOf(),
        });
        setMessageData('')
    }

    useEffect(() => {
        let n = props.ActiveItem.numero;
        if (props.ActiveItem.property) n = 1000;
        if (props.Transmition.vista === 'inicial') n = '';
        if (n !== ItemInShow) {
            setItemSelector(n);
            setItemInShow(n);
        }
    }, [props.ActiveItem, props.Transmition, ItemInShow])

    return (
        <div id="nvoMensaje">
            <label>Lote:
                <select
                    id="msj_lote"
                    value={ItemSelector}
                    onChange={e => setItemSelector(e.target.value)}
                >
                    <option value=''>Saludo</option>
                    {props.Items.map(item => {
                        return (
                            <option
                                key={'msgSelect' + item.id}
                                lote-id={item.id}
                                value={item.numero}
                            >
                                {'Lote ' + item.numero + ' - ' + item.titulo}
                            </option>
                        )
                    })}
                    <option value='1000'>Inmueble</option>
                </select>
            </label>
            <input
                id="msj_texto"
                type="text"
                placeholder="Escribe un mensaje aquí"
                value={MessageData}
                onChange={e => setMessageData(e.target.value)}
                onKeyUp={k => { if (k.code.toLowerCase().includes('enter')) sendMessage() }}
            />
            <button
                type="button"
                className="material-icons"
                onClick={() => sendMessage()}
            >
                send
            </button>
        </div>
    )
}
export default NewMessage
