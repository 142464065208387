import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './components/Root.js';
import Live from './components/Live.js';
import Admin from './components/Admin.js';
import Remote from './components/Remote.js'
import Show from './components/Show.js'
import Messages from './components/Messages.js'
import reportWebVitals from './reportWebVitals';
import Error from './error'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/vivo",
    element: <Live />,
  },
  {
    path: "/remoto",
    element: <Remote />,
  },
  {
    path: "/presentacion",
    element: <Show />,
  },
  {
    path: "/mensajes",
    element: <Messages />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
