import React from "react";

const Banner = (props) => {
    return (
        <div id="datos">
            <div className="textoLogo">
                <div>{props.ActiveItem.categoria ? props.ActiveItem.categoria[0] : ''}</div>
                <div>{props.ActiveItem.numero ? ('Lote ' + props.ActiveItem.numero) : ''}</div>
            </div>
        </div>
    );
}
export default Banner;







