import React from 'react'


const NewItemForm = (props) => {
    const modActiveItem = (prop, value) => {
        let ai = { ...props.ActiveItem }
        ai[prop] = value;
        props.setActiveItem(ai);
    }

    const setCategory = (stat, cat) => {
        let cats = props.ActiveItem.categoria || []
        if (stat)
            cats.push(cat);
        else if (cats.includes(cat))
            cats.splice(cats.indexOf(cat), 1);
        cats.sort()
        modActiveItem('categoria', cats);
    }

    return (
        <div
            className="form"
            style={{
                gridArea: 'newItemForm'
            }}
        >
            <fieldset>
                <legend                >
                    {props.new ? 'Cargar  Nuevo' : 'Modificar'} {props.Item ? 'Lote' : 'Inmueble'}
                </legend>
                <a
                    href='#'
                    className="volver material-icons-outlined"
                >
                    highlight_off
                </a>
                <div>
                    <label>
                        Publicado:
                        <input
                            type="checkbox"
                            onChange={e => modActiveItem('publicado', e.target.checked)}
                            defaultChecked={props.ActiveItem.publicado}
                        />
                    </label>
                    <label>
                        Cargar Fotos en Presentación:
                        <input
                            type="checkbox"
                            onChange={e => modActiveItem('cargarFotos', e.target.checked)}
                            defaultChecked={props.ActiveItem.cargarFotos}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Título:
                        <input
                            onChange={e => modActiveItem('titulo', e.target.value)}
                            value={props.ActiveItem.titulo || ''}
                            type="text"
                            required
                        />
                    </label>
                    <label>
                        Número:
                        <input
                            onChange={e => modActiveItem('numero', e.target.value)}
                            value={props.ActiveItem.numero || 0}
                            type="number"
                            min="0"
                        />
                    </label>
                    {props.Item ? (
                        <label>
                            Adj:
                            <input
                                type="checkbox"
                                onChange={e => modActiveItem('adjudicado', e.target.checked)}
                                defaultChecked={props.ActiveItem.adjudicado}
                            />
                        </label>
                    ) : ''}
                    <label>
                        V. Dir.:
                        <input
                            type="checkbox"
                            onChange={e => modActiveItem('vdirecta', e.target.checked)}
                            defaultChecked={props.ActiveItem.vdirecta}
                        />
                    </label>
                    {!props.Item ? (
                        <label>
                            Alquiler:
                            <input
                                type="checkbox"
                                onChange={e => modActiveItem('alquiler', e.target.checked)}
                                defaultChecked={props.ActiveItem.alquiler}
                            />
                        </label>
                    ) : ''}
                </div>
                <label>
                    Descripción:
                    <textarea
                        onChange={e => modActiveItem('descripcion', e.target.value)}
                        value={props.ActiveItem.descripcion || ''}
                        required
                    />
                </label>
                <div>
                    <details>
                        <summary>
                            Categorías:
                            <br />
                            <i
                                className='categoriesList'>
                                {!props.ActiveItem.categoria ? '' : props.ActiveItem.categoria.join(" - ")}
                            </i>
                        </summary>
                        <div
                            className='categoriesContainer'
                        >
                            {!!props.ActiveItem.categoriesList ?
                                props.ActiveItem.categoriesList.map(cat => {
                                    return (
                                        <label
                                            key={props.ActiveItem.id + cat[1] + Number(cat[0])}
                                        >
                                            <input
                                                value={cat[1]}
                                                type="checkbox"
                                                defaultChecked={cat[0]}
                                                className="checkCategoria"
                                                onChange={e => setCategory(e.target.checked, cat[1])}
                                            /> {cat}
                                        </label>
                                    )
                                }) : ''}
                        </div>
                        <input
                            type="text"
                            placeholder="Agregar"
                            className="agregarCategoria"
                            onBlur={e => props.addCategory(e.target)}
                        />
                    </details>
                    {props.Item ? (
                        <label>
                            Base:
                            <input
                                onChange={e => modActiveItem('base', e.target.value)}
                                value={props.ActiveItem.base || 0}
                                style={{ width: '10ch' }}
                                type="number"
                                min="0"
                            />
                        </label>
                    ) : ''}
                    {props.Item ? (
                        <label>
                            Venta Directa:
                            <input
                                onChange={e => modActiveItem('preciovdirecta', e.target.value)}
                                value={props.ActiveItem.preciovdirecta || 0}
                                style={{ width: '10ch' }}
                                type="number"
                                min="0"
                            />
                        </label>
                    ) : ''}
                    {!props.Item ? (
                        <label>
                            Coordenadas:
                            <input
                                onChange={e => modActiveItem('coordenadas', e.target.value)}
                                value={props.ActiveItem.coordenadas || ''}
                                type="text"
                            />
                        </label>
                    ) : ''}
                    {props.Item ? (
                        <label>
                            F. Sub:<br />
                            <input
                                type="date"
                                onChange={e => modActiveItem('fechasubasta', e.target.value)}
                                value={props.ActiveItem.fechasubasta || ''}
                            />
                        </label>
                    ) : ''}
                    {props.Item ? (
                        <label>
                            C. Ofertas:<br />
                            <input
                                onChange={e => modActiveItem('cierreofertas', e.target.value)}
                                value={props.ActiveItem.cierreofertas || ''}
                                style={{ width: '22ch' }}
                                type="datetime-local"
                            />
                        </label>
                    ) : ''}
                    {props.Item ? (
                        <label>
                            C. Vivo:<br />
                            <input
                                onChange={e => modActiveItem('cierrevivo', e.target.value)}
                                value={props.ActiveItem.cierrevivo || ''}
                                style={{ width: '10ch' }}
                                type="time"
                            />
                        </label>
                    ) : ''}

                    {/* <details>
                        <summary>
                            Oferta: $
                            <b>
                                <span>
                                    {props.ActiveItem.oferta || ''}
                                </span>
                            </b>
                        </summary>
                        <div
                            data-bollo-comp="lote-ofertas"
                        >
                            <template>
                                <div
                                    className="oferta"
                                >
                                    $
                                    <span
                                        data-bollo="oferta"
                                    />
                                    -
                                    <small
                                        data-bollo="fecha"
                                    />
                                    <div
                                        data-bollo="telefono"
                                    />
                                </div>
                            </template>
                        </div>
                    </details> */}
                </div>
                {!props.new ? (
                    <label
                        className="subeFoto"
                    >
                        <div
                            className="material-icons-outlined"
                        >
                            cloud_upload
                        </div>
                        <br />
                        Subir Fotos y Videos
                        <input
                            type="file"
                            accept="image/*, video/*"
                            className="subeFotoInput"
                            multiple
                            onChange={e => props.picUpload(e.target, props.ActiveItem.id)}
                        />
                        <br />
                        {props.ActiveUpload[0] ? (
                            <progress
                                value={props.ActiveUpload[1]}
                                max="100">
                                {props.ActiveUpload[1]}%
                            </progress>
                        ) : ''}
                    </label>
                ) : ''}
                {(!props.new || !!props.ActiveItem.titulo) ? (
                    <button
                        type="button"
                        className="grabar"
                        id="nuevoLote"
                        onClick={props.saveActiveItem}
                        disabled={!props.ActiveItem.titulo}
                    >
                        Grabar
                    </button>
                ) : ''}
            </fieldset>
            {((!props.ActiveItem.fotos || !props.ActiveItem.fotos.length) && !props.new) ? (
                <button
                    type="button"
                    className="borrar"
                    id="borrarLote"
                    onClick={e => props.deleteItem(props.ActiveItem.id)}
                >
                    Borrar
                </button>
            ) : ''
            }
        </div>
    )
}
export default NewItemForm;
