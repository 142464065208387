import React from 'react'
const Buttons = (props) => {
    return (
        <div id="mensajes">
            <div>
                {props.Messages.map(msg => {
                    return (
                        <div
                            key={msg.id}
                            msj-id={msg.id}
                            className={(msg.jc ? 'jc ' : '') + (msg.uid === props.ActiveUser.uid ? 'propio ' : '')}
                        >
                            <span className="nombre">{msg.nombre || ''}</span>
                            <span className="texto">{msg.texto || ''}</span>
                            <span
                                className={'lote' + (msg.lote ? '' : ' saludo') + (msg.lote.toString() === '1000' ? ' material-icons' : '')}
                            >
                                {msg.lote.toString() === '1000' ? 'home' : msg.lote}
                            </span>
                            <span className="hora">
                                {msg.ts ? msg.ts.toDate().toLocaleString().split(' ')[1] : ''}
                            </span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default Buttons