import React, { useState, useEffect, useRef } from "react";
import { db, auth, storage } from "./firebase";
import { doc, setDoc, collection, onSnapshot, deleteDoc, updateDoc, arrayUnion } from "firebase/firestore"
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { ref, deleteObject, uploadString, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import setCss from "./setCss"

import Nav from "./admin-nav";
import ItemsList from "./admin-itemslist";
import NewItemForm from "./admin-newitem";
import PicturesContainer from "./admin-pictures";

function Admin(props) {
  const [AuctionDate, setAuctionDate] = useState("");
  const [Items, setItems] = useState([]);
  const [ItemsCat, setItemsCat] = useState([]);
  const [Estate, setEstate] = useState("");
  const [EstateCat, setEstateCat] = useState([]);
  const [NewItem, setNewItem] = useState('');
  const [NewProperty, setNewProperty] = useState('');
  const [Hash, setHash] = useState(window.location.hash.slice(1));
  const [ActiveItem, setActiveItem] = useState({});
  const [ActiveUpload, setActiveUpload] = useState([false, 0]);
  const [Loaded, setLoaded] = useState(false);
  const signingOut = useRef(false);

  useEffect(() => {
    setCss('/assets/admin.css');
    setLoaded(true);
    if (Loaded) {
      window.addEventListener('hashchange', () => {
        setHash(window.location.hash.slice(1))
      });
      onAuthStateChanged(auth, (user) => {
        if (user) {
          onSnapshot(doc(db, "Bollo/JCarrizo/Usuarios", user.uid), (userDoc) => {
            if (userDoc.exists() && userDoc.data().admin) {
              onSnapshot(
                collection(db, "Bollo/JCarrizo/Lotes"),
                (querySnapshot) => {
                  let items = {},
                    categories = [];
                  for (let doc of querySnapshot.docs) {
                    items[doc.id] = {
                      ...doc.data(),
                      id: doc.id,
                    }
                    if (doc.data().categoria)
                      categories.push(...doc.data().categoria)
                  }
                  setItems(items)
                  setItemsCat([...new Set(categories.sort())]);
                })
              onSnapshot(
                collection(db, "Bollo/JCarrizo/Inmuebles"),
                (querySnapshot) => {
                  let items = {},
                    categories = [];
                  for (let doc of querySnapshot.docs) {
                    items[doc.id] = {
                      ...doc.data(),
                      id: doc.id,
                    }
                    if (doc.data().categoria)
                      categories.push(...doc.data().categoria)
                  }
                  setEstate(items)
                  setEstateCat([...new Set(categories.sort())]);
                })
              onSnapshot(
                doc(collection(db, "Bollo"), "vivo"),
                (querySnapshot) => {
                  setAuctionDate(querySnapshot.data().proxSubasta || '')
                })
            } else {
              signingOut.current = true;
              signOut(auth).then(() => {
                window.location = '/';
              })
            }
          })
        } else {
          if (!signingOut.current) {
            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider)
          }
        }
      });
    }
  }, [Loaded]);

  useEffect(() => {
    if (!NewItem || !!Items[NewItem]) {
      let id = doc(collection(db, "Bollo/JCarrizo/Lotes")).id
      setNewItem(id);
    }
  }, [Items, NewItem]);

  useEffect(() => {
    if (!NewProperty || !!Estate[NewProperty]) {
      let id = doc(collection(db, "Bollo/JCarrizo/Inmuebles")).id
      setNewProperty(id);
    }
  }, [Estate, NewProperty]);

  useEffect(() => {
    const categoriesList = (item, cats = []) => {
      let list = [];
      if (!item.categoria)
        item.categoria = [];
      for (let c of cats)
        list.push([
          Number(!item.categoria.includes(c)),
          c
        ])
      list.sort().map(c => c[0] = !c[0])
      return list
    }
    if (!!Items[Hash]) {
      setActiveItem({
        ...Items[Hash],
        categoriesList: categoriesList(Items[Hash], ItemsCat)
      });
    } else if (!!Estate[Hash]) {
      setActiveItem({
        ...Estate[Hash],
        categoriesList: categoriesList(Estate[Hash], EstateCat)
      });
    } else if (Hash === NewItem) {
      setActiveItem({
        id: NewItem,
        categoriesList: categoriesList({}, ItemsCat)
      });
    } else if (Hash === NewProperty) {
      setActiveItem({
        id: NewProperty,
        categoriesList: categoriesList({}, EstateCat)
      });
    }
  }, [Items, Estate, ItemsCat, EstateCat, Hash, NewItem, NewProperty]);

  const saveItemProperty = (id, property, value) => {
    let val = {};
    val[property] = value;
    setDoc(doc(db, "Bollo/JCarrizo/" + itemCollection(id), id),
      val,
      { merge: true });
  }

  const deleteItem = id => {
    deleteDoc(doc(db, "Bollo/JCarrizo/" + itemCollection(id), id));
    window.location = '#';
  }

  const saveAuctionDate = (t) => {
    setDoc(doc(db, "Bollo", "vivo"), {
      proxSubasta: t
    }, { merge: true });
  }

  const addCategory = (el) => {
    const addCatToList = (list, c) => {
      if (!Array.isArray(list))
        list = [];
      list.push(c);
      return [...new Set(list.sort())];
    }
    if (el.value) {
      setActiveItem({
        ...ActiveItem,
        categoria: addCatToList(ActiveItem.categoria, el.value)
      })
      if (!!Items[ActiveItem.id])
        setItemsCat(
          addCatToList(ItemsCat, el.value)
        )
      if (!!Estate[ActiveItem.id])
        setEstateCat(
          addCatToList(EstateCat, el.value)
        )
    }
    el.value = '';
  }

  const saveActiveItem = () => {
    const item = { ...ActiveItem },
      id = ActiveItem.id
    delete item.id
    delete item.categoriesList
    if (!!id && !!item.titulo)
      setDoc(doc(db, "Bollo/JCarrizo/" + itemCollection(id), id), item);
  }

  const itemCollection = id => {
    return (!!Items[id] || id === NewItem) ?
      'Lotes' : (
        (!!Estate[id] || id === NewProperty) ?
          'Inmuebles' : ''
      )
  }

  const picUpload = (el, id) => {
    for (let file of el.files) {
      if (file.type.split('/')[0] === 'image') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = event => {
          const imag = new Image();
          imag.src = event.target.result;
          imag.onload = e => {
            let width = 640,
              height = 640;
            const elem = document.createElement('canvas');
            elem.width = width;
            elem.height = height;
            if ((imag.width / imag.height) < (width / height)) elem.width = Math.round(height / imag.height * imag.width);
            else elem.height = Math.round(width / imag.width * imag.height);
            const ctx = elem.getContext('2d');
            ctx.drawImage(imag, 0, 0, elem.width, elem.height);
            const dataUrl = ctx.canvas.toDataURL('image/jpeg', .8);
            uploadString(
              ref(storage, itemCollection(id) + '/' + id + '-' + new Date().getTime() + '.jpg'),
              dataUrl,
              'data_url'
            ).then((snapshot) => {
              getDownloadURL(snapshot.ref)
                .then((url) => {
                  addPic(url, id);
                })
            });
          }
        }
      } else
        if (file.type.split('/')[0] === 'video') {
          const uploadTask = uploadBytesResumable(
            ref(storage, 'videos/' + id + '-' + new Date().getTime() + '.mp4'),
            file
          );

          uploadTask.on('state_changed',
            (snapshot) => {
              setActiveUpload([true, Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)]);
            },
            (error) => { },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                addPic(url, id);
                setActiveUpload([false, 0]);
              });
            }
          );

          // uploadBytes(
          //   ref(storage, 'videos/' + id + '-' + new Date().getTime() + '.mp4'),
          //   file
          // ).then((snapshot) => {
          //   getDownloadURL(snapshot.ref)
          //     .then((url) => {
          //     })
          // });
        };
    }
  };

  const addPic = (url, id) => {
    updateDoc(
      doc(db, "Bollo/JCarrizo/" + itemCollection(id), id),
      { fotos: arrayUnion(url) }
    );
  }

  const deletePic = url => {
    const storagePath = url.split('/o/')[1].split('?')[0].replaceAll('%2F', '/');
    deleteObject(ref(storage, storagePath));
    const pics = ActiveItem.fotos || [],
      collection = !!Items[ActiveItem.id] ?
        'Lotes' : (
          !!Estate[ActiveItem.id] ?
            'Inmuebles' : ''
        )
    if (pics.includes(url))
      pics.splice(pics.indexOf(url), 1);
    setDoc(doc(db, "Bollo/JCarrizo/" + collection, ActiveItem.id),
      { fotos: pics },
      { merge: true });
  }

  return (
    <div id="App"
      className="admin"
    >
      {!Hash ? <Nav
        AuctionDate={AuctionDate}
        saveAuctionDate={saveAuctionDate}
        Items={Items}
      /> : ''}
      {(Hash && !!ActiveItem.id) ?
        <NewItemForm
          new={Hash === NewItem || Hash === NewProperty}
          Item={!!Items[Hash] || Hash === NewItem}
          ActiveItem={ActiveItem}
          setActiveItem={setActiveItem}
          addCategory={addCategory}
          saveActiveItem={saveActiveItem}
          picUpload={picUpload}
          ActiveUpload={ActiveUpload}
          deleteItem={deleteItem}
        />
        : ''}
      <ItemsList
        Title='Lotes'
        Items={Items}
        saveItemProperty={saveItemProperty}
        newItem={NewItem}
        ActiveItem={ActiveItem}
      />
      <ItemsList
        Title='Inmuebles'
        Items={Estate}
        saveItemProperty={saveItemProperty}
        newItem={NewProperty}
        ActiveItem={ActiveItem}
      />
      {(Hash && ActiveItem.fotos) ?
        <PicturesContainer
          ActiveItem={ActiveItem}
          deletePic={deletePic}
        />
        : ''}
      <div
        id='Messages'
      />
    </div>
  );
}

export default Admin;
