const setCss = path => {
    path = Array.isArray(path) ? path : [path];
    for (let css of document.head.querySelectorAll('.customStyleSheet')) {
        let remove = true;
        path.map(
            p => remove =
                css.href.includes(p) ? false : remove
        )
        if (remove) css.remove();
    }
    for (let p of path) {
        if (!document.head.querySelector('.customStyleSheet[href="' + p + '"]')) {
            let css = document.createElement('link');
            css.className = 'customStyleSheet';
            css.rel = 'stylesheet';
            css.href = p;
            document.head.appendChild(css);
        }
    }
}

export default setCss;

